import React from 'react';
import { 
  useStaticQuery, 
  graphql 
} from "gatsby"
import Img from "gatsby-image"
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AliceCarousel from 'react-alice-carousel'
import "react-alice-carousel/lib/alice-carousel.css";
import { GetAlias } from '../../../utils/func';
// const useStyles = makeStyles((theme) => ({
  
// }));

const OurWorks = () => {
  // const classes = useStyles();
  // const theme = useTheme();

  const responsive = {
    0: { items: 1 },
    600: { items: 2 },
    960: { items: 3 },
    1280: { items: 4 },
    1920: { items: 5 }
  }

  const stagePadding = {
    paddingLeft: 0,     // in pixels
    paddingRight: 0
  }

  const data = useStaticQuery(graphql`
    query OurWorksQuery {
      allContentfulOurWorks {
        edges {
          node {
            ourWorksImage {
              fluid(maxHeight: 400) {
                ...GatsbyContentfulFluid_withWebp
              }
              title
            }
            brand
          }
        }
      }
    }
  `);

  const handleOnDragStart = (e) => e.preventDefault();
  const domain = "moorhousecoating";
  if (typeof window !== 'undefined') {
    const domain = GetAlias(window.location.hostname);
  }
  const brand = domain === "moorhousecoating" ? "parkcity" : domain === "localhost" ? "parkcity" : domain;
  const isPrimaryBrand = brand === "parkcity" ? true : false;
  
  const ourWorks = isPrimaryBrand ? [
    ...data.allContentfulOurWorks.edges.filter(({ node }) => node.brand === "parkcity")[0].node.ourWorksImage
  ] : [
    ...data.allContentfulOurWorks.edges.filter(({ node }) => node.brand === brand)[0].node.ourWorksImage,
    ...data.allContentfulOurWorks.edges.filter(({ node }) => node.brand === "parkcity")[0].node.ourWorksImage
  ];
  
  return (
    <>
      <Container>
        <Box
          pt={5}
          pb={2}
        >
          <Typography 
            variant="h2"
          >
            What We Have Done
          </Typography>
        </Box>
      </Container>
      <AliceCarousel 
        responsive={responsive} 
        dotsDisabled={true} 
        buttonsDisabled={true} 
        stagePadding={stagePadding} 
        autoPlay={true} 
        autoPlayInterval={2200}
      >
        {
          ourWorks.map(item => ( 
            <Img 
              key={item.title} 
              fluid={item.fluid} 
              onDragStart={handleOnDragStart} 
              alt={item.title} 
              loading="lazy" 
              style={{height: 350}} 
            />
            // <img src={item.fluid.src} alt={item.title} onDragStart={handleOnDragStart} loading="lazy" />
            // <Img key={item.title} fixed={item.fixed} onDragStart={handleOnDragStart} className="yours-custom-class" />
          ))
        }
      </AliceCarousel>
    </>
  )
}

export default OurWorks