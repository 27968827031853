import React from "react"
import { 
  useStaticQuery, 
  graphql 
} from "gatsby"
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Layout from "../layout/layout"
import SEO from "../seo/seo"
import OurWorks from "../components/index/ourWorks/ourWorks"

const Gallery = () => {
  const data = useStaticQuery(graphql`
    query GalleryQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  
  return (
    <Layout>
      <SEO title={`Gallery | ${data.site.siteMetadata.title}`} />
        <Box
          py={7}
        >
          <OurWorks /> 
        </Box>
    </Layout>
  )
}

export default Gallery