export const GetAlias = (url, subdomain) => {
  subdomain = subdomain || false;

  url = url.replace(/(https?:\/\/)?(www.)?/i, '');

  if (!subdomain) {
      url = url.split('.'); 
  }

  console.log('before', url)

  if (url.length >= 1) {
    url = url[0];
  }

  console.log(url);
  return url;
}